import { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { useParams } from "react-router-dom";
import {
  allImage,
  getNonMatchingValues,
  replaceUrl,
  updateImageSrcInHtml,
  getKey,
} from "../utils/replaceUrl";
import getLink from "../utils/getLinks";

const useGetActivityByType = (filterType) => {
  const [activity, setActivity] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchActivityByType = async () => {
      try {
        setLoading(true);
        const response = await API.graphql({
          query: queries.listActivities,
           variables: {
             filter: {
               type: { eq: filterType },
             },
           },
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        });

        if (
          !response ||
          !response.data ||
          !response.data.listActivities ||
          !response.data.listActivities.items
        ) {
          throw new Error("No data found");
        }

        const { items } = response.data.listActivities;

        if (!items || items.length === 0) {
          setActivity(null);
          setLoading(false);
          return;
        }

        const updatedActivity = await Promise.all(
          items.map(async (item) => {
            if (item.description) {
              item.description = await updateImageSrcInHtml(
                item.description,
                getLink,
                getKey
              );
            }
            return item;
          })
        );

        setActivity(updatedActivity[0]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching activity by type:", error);
        setLoading(false);
      }
    };

    fetchActivityByType();
  }, [id, filterType]);

  return { activity, loading };
};

export default useGetActivityByType;
